import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../server/UtilService';
import { APIService } from '../../server/APIService';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit {
  /**搜索结果集合 */
  FTList: any = [];
  /**历史搜索结果集合 */
  SearchList: any = new Array();
  /**瀑布流 */
  subscribeScoll: any;
  /**瀑布流 */
  more_value = "加载更多";
  /**当前页码 */
  PageIndex: number = 1;
  /**总页码 */
  PageCount: number = 1;
  /**搜索文本框 */
  SearchString: any = "";
  /**显示状态 1搜索历史，2搜索结果*/
  ShowType = 1;
  constructor(private utls: UtilService, private apiService: APIService) {
    //获取用户编号
    let UID = this.utls.GetLocalData("UID");
    if (UID <= 0) {
      this.utls.Alert("您还没有登录哦，请登录！");
      this.utls.GoBack();
    }
    else {
      //获取历史记录列表
      let SearchListOld = this.utls.GetLocalData("SearchList");
      if (!this.utls.IsNullOrEmpty(SearchListOld)) {
        this.SearchList = SearchListOld.split(",");
      }
    }
  }

  ///加载方法
  ngOnInit() {
    // 监听滚动条滚动
    this.subscribeScoll = fromEvent(window, 'scroll').subscribe((event) => {
      this.onWindowScroll(); // 调用
    });
  }

  /**
  * 获取不同方向上滚动的距离
  */
  onWindowScroll() {
    var top = document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight;
    var content_height = window.innerHeight;
    if (height - top < content_height) {
      this.search_more();
    }
  }

  /**
   * 加载更多 
   */
  search_more() {
    if (this.PageIndex < this.PageCount) {
      this.PageIndex++;
      this.GetDataList();
    }
  }

  /**
 *  返回顶部 
 */
  top() {
    //$('html,body').animate({ scrollTop: 0 }, 300);
    window.scroll(0, 0);
  }

  /**
   * 搜索
   */
  clickSearch() {
    if (this.utls.IsNullOrEmpty) {
      //保存数据
      //去重
      if (this.SearchList.indexOf(this.SearchString) > -1) {
        this.SearchList.splice(this.SearchList.indexOf(this.SearchString), 1)
      }
      //超量删除
      if (this.SearchList.length >= 6) {
        this.SearchList.splice(5, 10);
      }
      this.SearchList.splice(0, 0, this.SearchString);
      this.utls.SaveLocalData("SearchList", this.SearchList);
      //查询
      this.ShowType = 2;
      this.PageIndex = 1;
      this.GetDataList();
    }
    else {
      this.utls.Alert("请输入要搜索的内容！");
    }
  }

  /**
   * 获取音频集合
   */
  GetDataList() {
    this.apiService.MFrequency_GetFrequenciesAndTypePaged(this.SearchString, this.PageIndex, 10, 2, (data) => {
      if (this.PageIndex == 1) {
        this.FTList = data.Result;
      }
      else {
        for (var i = 0; i < data.Result.length; i++) {
          this.FTList.push(data.Result[i]);
        }
      }
      this.PageCount = data.PageCount;
      if (this.PageIndex >= this.PageCount) {
        this.more_value = "已全部加载";
      }
      else {
        this.more_value = "加载更多";
      }
    });
  }

  /**
   * 搜索历史列表点击事件
   * @param FId 
   */
  clickSearchOld(searchOldString) {
    this.SearchString = searchOldString;
    //查询
    this.clickSearch();
  }

  /**
   * 图书、类型点击事件
   * @param TypeId 
   * @param TFId 
   */
  clickTFItem(TypeId, TFId) {
    if (TypeId == 1) {
      this.utls.SetUrlParams("frequencyitem", { "fid": TFId });
    }
    else if (TypeId == 2) {
      this.utls.SetUrlParams("frequencylist1", { "fTypeId": TFId, "pageId": 1 });
    }
  }

  /**
   * 清空搜索历史
   */
  clickDeleteAllSearch() {
    this.utls.Confirm("确定清空历史记录吗？", () => {
      this.utls.DelLocalData("SearchList");
      this.PageIndex = 1;
      this.SearchString = "";
      this.GetDataList();
    });
  }

  /**
   * 返回上一页
   */
  clickBack() {
    if (this.ShowType == 2) {
      this.ShowType = 1;
      this.PageIndex = 1;
      this.SearchString = "";
      this.FTList = [];
    }
    else {
      this.utls.GoBack();
    }
  }
}
