import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../server/UtilService';
import { APIService } from '../../server/APIService';
import { DomSanitizer } from '@angular/platform-browser';
//import * as $ from 'jquery';

declare var $: any;
declare var ckplayer: any;

@Component({
  selector: 'app-frequencyitempdf',
  templateUrl: './frequencyitempdf.component.html',
  styleUrls: ['./frequencyitempdf.component.scss']
})

export class FrequencyItemPdfComponent{
  pm: any;
  /**pdf地址 */
  FPath:any;
  FPath2:any;
  constructor(private utls: UtilService, private apiService: APIService,private sanitizer: DomSanitizer) {
    /** 接收参数 */
    this.pm = this.utls.GetUrlParams();

    if (this.pm == null) {
      this.utls.Alert("获取信息失败！");
      this.utls.GoBack();
    }
    else {
      //判断内容中的链接
      if (!this.utls.IsNullOrEmpty(this.pm.pdfUrl)) {
        //判断是否存在pdf
        if (this.pm.pdfUrl.indexOf(".pdf") > 0) {
          //存在pdf
         //this.FPath = "http://bookapi.qy99.com/Upload/1.pdf";
         //this.FPath = this.pm.pdfUrl;
         //this.FPath2 = this.sanitizer.bypassSecurityTrustResourceUrl(this.pm.pdfUrl); 
         this.FPath = this.pm.pdfUrl;
         //this.FPath = "http://192.168.0.65:8096/1.pdf";//this.pm.pdfUrl;
        }
      }
    }
  }

  /**
   * 返回上一页
   */
  clickBack() {
    this.utls.GoBack();
  }
}
