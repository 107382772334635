import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../server/UtilService';
import { APIService } from '../../server/APIService';
//import * as $ from 'jquery';

declare var $: any;
declare var ckplayer: any;
declare const audioplayer:any;

@Component({
  selector: 'app-frequencyitem',
  templateUrl: './frequencyitem.component.html',
  styleUrls: ['./frequencyitem.component.scss']
})

export class FrequencyItemComponent implements OnInit {
  pm: any;
  /**当前类别实体 */
  FTypeModel = {
    "TId": 0,
    "TName": "",
    "FId": 0,
    "TTime": "",
    "TClick": 0,
    "TTop": 0,
    "CId": 0,
    "TypeImage": ""
  }
  /**当前图书信息 */
  FrequencyModel = {
    "FId": 0,
    "FName": "",
    "FPath": "",
    "TId": 0,
    "FTime": "",
    "FClick": 0,
    "UId": 0,
    "CardId": 0,
    "FContent": "",
    "VideoDescription": "",
    "TimeString": "",
    "TimeLong": 0,
    "FileSize": 0
  }
  /**当前笔记信息 */
  NoteModel = {
    "NId": 0,
    "FId": 0,
    "NTime": "",
    "NContent": "",
    "NEndTime": "",
    "PegTime": "",
    "NTitle": ""
  }
  /**显示状态 0内容，1笔记*/
  PageShow: number = 0;
  /**文件基础路径 */
  WebFileUrl = "";
  /**pdf基础路径 */
  WebPdfUrl = "";
  /**显示状态 0无音视频，1音频，2视频*/
  fileYS: number = 0;
  /**音频播放状态 0播放，1暂停*/
  audioState: any = 1;
  /**音频播放当前播放时间*/
  audioCurrentTime: any = "";
  //定时器
  private timer;
  /**视频控件 */
  player: any;
  /**音视频 播放秒数 */
  videoSeek: number = 0;
  constructor(private utls: UtilService, private apiService: APIService) {
    /** 接收参数 */
    this.pm = this.utls.GetUrlParams();

    if (this.pm == null) {
      this.utls.Alert("获取信息失败！");
      this.utls.GoBack();
    }
    else {
      if (this.pm.fid == null) {
        this.utls.Alert("获取信息失败！");
        this.utls.GoBack();
      }
      else {
        //获取文件基础路径
        this.WebFileUrl = this.utls.Config.WebFileUrl;
        //获取当前音频信息
        this.apiService.MFrequency_GetFrequencyInfo(this.pm.fid, false, (dataFrequency) => {
          if (dataFrequency == null) {
            this.utls.Alert("获取信息失败！");
            this.utls.GoBack();
          }
          this.FrequencyModel = dataFrequency;
          //console.log(this.FrequencyModel);
          //获取当前类型信息
          this.apiService.MTypes_GetTypesByID(this.FrequencyModel.TId, (dataFT) => {
            if (dataFT == null) {
              this.utls.Alert("获取信息失败！");
              this.utls.GoBack();
            }
            this.FTypeModel = dataFT;
          });

          //增加点击率
          this.apiService.MFrequency_UpdateTypesClick(this.pm.fid, (dataClick) => {
          });
          //获取用户编号
          let UID = this.utls.GetLocalData("UID");
          if (UID > 0) {
            //获取用户笔记信息
            this.apiService.MNote_GetNoteInfoByFid(this.pm.fid, (dataNote) => {
              if (dataNote != null) {
                this.NoteModel = dataNote;
              }
            });
            //增加播放记录
            let recordModel = {
              "UID": UID,
              "FID": this.pm.fid,
              "PegTime": this.utls.GetNowFormatDate()
            };
            this.apiService.MFrequency_AddFreHistory(recordModel, (dataRecord) => {
            });
          }   

          if (!this.utls.IsNullOrEmpty(this.FrequencyModel.FPath)) {
            //测试
            // this.WebFileUrl="http://192.168.0.65:8096/";
            // this.FrequencyModel.FPath = "1.pdf";
            // console.log(this.FrequencyModel.FPath);
            if (this.FrequencyModel.FPath.length > 4) {
              let lastString = this.FrequencyModel.FPath.substring(this.FrequencyModel.FPath.lastIndexOf("."));//截取后缀名
              if (lastString == ".mp3") {
                //判断如果是音频
                this.fileYS = 1;
                this.audioPalyInit();
              }
              else if (lastString == ".flv" || lastString == ".mp4") {
                //判断如果是视频
                this.fileYS = 2;
                this.audioPalyInit();
              }
              else if (lastString == ".pdf") {
                //如果是pdf
                //this.fileYS = 3;
              }
            }
          }
          //判断内容中的链接
          if (!this.utls.IsNullOrEmpty(this.FrequencyModel.FContent)) {
            setTimeout(function () {
              $(".FContentDivClass").attr("aria-label", "图书介绍：" + $(".FContentClass").text());
            }, 500);
            //判断是否存在pdf
            if (this.FrequencyModel.FContent.indexOf(".pdf") > 0) {
              //获取pdf基础路径
              var webPdfUrlJs = this.utls.Config.WebPdfUrl;
              var webFileUrlJs = this.WebFileUrl;
              //存在pdf
              setTimeout(function () {
                $(".FContentClass").find("a").each(function () {
                  let lastString = $(this).attr("href").substring($(this).attr("href").lastIndexOf("."));//截取后缀名
                  if (lastString == ".pdf") {
                    //如果是pdf
                    //console.log($(this).attr("href"));
                    var fileHref = $(this).attr("href").replace(new RegExp(webFileUrlJs, 'g'), webPdfUrlJs);
                    //给data-href href去掉
                    //$(this).attr("data-href", $(this).attr("href"));
                    $(this).attr("data-href", fileHref);
                    $(this).removeAttr("href");
                    $(this).attr("tabindex", "0");
                    $(this).attr("role", "button");
                    $(this).click(() => {
                      //console.log($(this).attr("data-href"));
                      utls.SetUrlParams("frequencyitempdf", { "pdfUrl": $(this).attr("data-href") });
                    });
                  }
                });
              }, 500);

            }
          }
        });
      }
    }
  }

  /**音视频播放 */
  audioPalyInit() {
    setTimeout(() => {
      if (this.fileYS == 2) {
        /**视频 */
        this.videoPlay();
      }
      else if (this.fileYS == 1) {
        /**音频 */
        $('#video0').audioPlayer();
        if (this.utls.IsNullOrEmpty(this.FrequencyModel.TimeString)) {
          setTimeout(() => {
            this.FrequencyModel.TimeString = this.utls.SecondsToTime($("#video0")[0].duration);
          }, 2000);
        }
        this.timer = setInterval(() => {//设置定时刷新事件，每隔1秒刷新
          this.audioCurrentTime = this.utls.SecondsToTime($("#video0")[0].currentTime);
        }, 1000);
      }
    }, 1000);
  }

  videoPlay() {//scope.videocb.videocallback(scope.videocb)
    let videoObject = {
      container: '#video',
      variable: 'player',//该属性必需设置，值等于下面的new chplayer()的对象           
      //loaded: this.loadedHandler, //当播放器加载后执行的函数
      mobileCkControls: true,//是否在移动端（包括ios）环境中显示控制栏
      mobileAutoFull: false,//在移动端播放后是否按系统设置的全屏播放
      autoplay: false,
      //video: this.WebFileUrl +this.FrequencyModel.FPath      //视频地址
      video: this.FrequencyModel.FPath      //视频地址
    };
    this.player = new ckplayer(videoObject);

    setTimeout(() => {
      this.player.addListener('time', this.timeHandler);
    }, 1000);
  }

  loadedHandler() {
    this.player.addListener('time', this.timeHandler);
  }

  timeHandler(time) {
    this.videoSeek = time;
  }

  ///加载方法
  ngOnInit() {
  }

  ionViewWillLeave() {
    if (this.fileYS == 2) {
      /**视频 */
      this.player.videoPause();
    }
    else if (this.fileYS == 1) {
      //var videoID = '#video0';
      $('#video0').pause();
      //$(".audioplayer-playing").removeClass("audioplayer-playing");
    }
  }

  ngOnDestroy() {
    if (this.fileYS == 2) {
      /**视频 */
      this.player.videoPause();
    }
    else if (this.fileYS == 1) {
      $(".audioplayer").remove();
    }
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  /**
   * 返回上一页
   */
  clickBack() {
    this.utls.GoBack();
  }

  /**
   * 保存笔记
   */
  clickSubmit() {
    //根据UserToken获取用户信息 
    let UID = this.utls.GetLocalData("UID");
    if (this.NoteModel.NId == 0) {
      //新建
      let NoteAddModel = {
        "UID": UID,
        "FID": this.FrequencyModel.FId,
        "NContent": this.NoteModel.NContent,
        "PegTime": this.utls.GetNowFormatDate()
      }
      this.apiService.MNote_AddNote(NoteAddModel, (dataBool) => {
        if (dataBool) {
          this.utls.Alert("笔记保存成功！");
          this.PageShow = 0;
        }
        else {
          this.utls.Alert("笔记保存失败！");
        }
      });
    }
    else {
      //修改
      //新建
      let NoteUpdateModel = {
        "UID": UID,
        "NID": this.NoteModel.NId,
        "NContent": this.NoteModel.NContent
      }
      this.apiService.MNote_UpdateNote(NoteUpdateModel, (dataBool) => {
        if (dataBool) {
          this.utls.Alert("笔记更新成功！");
          this.PageShow = 0;
        }
        else {
          this.utls.Alert("笔记更新失败！");
        }
      });
    }
  }

  /**
  * 填写笔记
  */
  clickAddNote() {
    this.PageShow = 1;
  }

  /**音视频 播放/暂停 */
  AudioP() {
    if (this.fileYS == 2) {
      /**视频 */
      //this.player.playOrPause();
    }
    else if (this.fileYS == 1) {
      /**音频 */
      var videoID = '#video0';
      if ($(videoID)[0].paused) {
        $(videoID)[0].play();
        this.audioState = 0;

      } else {
        $(videoID)[0].pause();
        this.audioState = 1;
      }
    }
  }

  /**音视频 快退 */
  AudioQ() {
    if (this.fileYS == 2) {
      /**视频 */
      //this.player.fastBack();
    }
    else if (this.fileYS == 1) {
      /**音频 */
      var currentTime = $("#video0")[0].currentTime;
      currentTime = currentTime - 15;
      $("#video0")[0].currentTime = currentTime;
    }
  }

  /**音视频 快进 */
  AudioW() {
    if (this.fileYS == 2) {
      /**视频 */
      //this.player.fastNext();
    }
    else if (this.fileYS == 1) {
      /**音频 */
      var currentTime = $("#video0")[0].currentTime;
      currentTime = currentTime + 15;
      $("#video0")[0].currentTime = currentTime;
    }
  }
}
