import { Pipe, PipeTransform } from '@angular/core';
import * as Moment from 'moment';
/**
 * Generated class for the MattDamonPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'JsonDate',
})
export class MattDamonPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  // transform(value: string, ...args) {
  //   return value.toLowerCase();
  // }

  transform(d: Date | Moment.Moment | string, args?: any[]):string {
     // utc add 8 hours into beijing 
     let rv = Moment(d).format(args[0]); return rv;
     }


  
}
