import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import sha256 from "crypto-js/sha256";
import Base64 from 'crypto-js/enc-base64';
import CryptoJS from 'crypto-js/crypto-js';


@Injectable()
export class UtilService {

    //配置文件
    Config = {
        //授权网址
        //WebApiAuthUrl: 'http://misapi.qy99.com',
        //WebApiAuthUrl: 'http://10.29.255.3:80',
        //WebApiAuthUrl: 'http://192.168.0.65:8086',
        WebApiAuthUrl: 'https://bookapi.zhugesoft.cn',
        //WebApiAuthUrl: 'http://bookapi.zhuge-soft.cn',
        //api地址
        //WebApiUrl: 'http://localhost:59567/api/',
        //WebApiUrl: 'http://192.168.0.64:83/api/',
        //WebApiUrl: 'http://bookapi.qy99.com/api/',
        //WebApiUrl: 'http://10.29.255.3:85/api/',
        WebApiUrl: 'https://bookapi.zhugesoft.cn/api/',
        //WebApiUrl: 'http://bookapi.zhuge-soft.cn/api/',
        //图片地址
        //WebImgUrl: 'https://ycteachapi.zhuge-soft.cn',
        tile: '有声数字图书馆管理系统',
        //视频地址
        //WebFileUrl: "http://bookapi.qy99.com/Upload/",
        //WebFileUrl: "http://10.29.255.3:85/Upload/",
        WebFileUrl: "https://bookapi.zhugesoft.cn/Upload/",
        //WebFileUrl: "http://bookapi.zhuge-soft.cn/Upload/",
        //pdf地址
        //WebPdfUrl:"http://192.168.0.65:8088/pdf/",
        WebPdfUrl:"https://bookapi.zhugesoft.cn/pdf/pdf/",
        //WebPdfUrl:"http://bookpdf.zhuge-soft.cn/pdf/",
        //加密key
        PwdKey: 'czq',
        // 每个文件切片大小定为1MB
        bytesPerPiece: 1024 * 1024,

        AppSecret: 'AC099D5E99413586A37C27009A33EA64',
        AppKey: 'dbc28ee158e145119585c796834fdc29'
    };

    constructor(private pm: ActivatedRoute, private router: Router, private location: Location) {
    }

    /**
      * 获取路由参数值
      * @param level  路由基数
      */
    GetUrlParams() {
        let ar: any;
        let url: any;
        try {


            if (this.pm.children.length == 1) {
                if (this.pm.firstChild.children.length == 0) {
                    url = this.pm.firstChild.snapshot.params["Param"];

                } else if (this.pm.firstChild.children.length == 1) {
                    if (this.pm.firstChild.firstChild.children.length == 0) {
                        url = this.pm.firstChild.firstChild.snapshot.params["Param"];
                    }
                }

            }
            if (!this.IsNullOrEmpty(url)) {

                ar = JSON.parse(decodeURI(url));
            }
        } catch (ex) {

            this.GoBack();
            throw new Error("参数错误");

        }
        return ar;

    }
    /**
     * 跳转页面
     * @param pathKey 
     * @param pm 
     */

    SetUrlParams(pathKey: string, pm: any = null) {
        if (pm == null) {

            this.router.navigate(["/" + pathKey]);
        } else {

            let pmar = encodeURI(JSON.stringify(pm));
            this.router.navigate(['/' + pathKey, pmar]);
        }

    }

    /**
     * 跳转页面
     * @param pathKey 
     * @param pm 
     */

    SetUrlParams1(pm: any = null) {
        if (pm == null) {
            return "";
        } else {
            let pmar = encodeURI(JSON.stringify(pm));
            return pmar;
        }
    }



    /**
     * 返回上一个路由
     */
    GoBack() {
        this.location.back();
    }
    //保存本地数据
    SaveLocalData(key, value) {
        if (window.localStorage) {
            localStorage.setItem(key, value);
        }
        else if (window.sessionStorage) {
            sessionStorage.setItem(key, value);
        }
        else if (window.navigator.cookieEnabled) {
            let strsec = 12 * 60 * 60 * 1000;;
            let exp = new Date();
            exp.setTime(exp.getTime() + strsec * 1);
            document.cookie = key + "=" + encodeURI(value) + ";expires=" + exp.toDateString() + ";path=/";
        }
        else {
            alert("浏览器不支持本地存储！");
        }
    }
    //获取本地数据
    GetLocalData(key) {

        if (window.localStorage) { return localStorage.getItem(key); }
        else if (window.sessionStorage) { return sessionStorage.getItem(key); }
        else if (window.navigator.cookieEnabled) {
            let arr, reg = new RegExp("(^| )" + key + "=([^;]*)(;|$)");
            if (arr = document.cookie.match(reg))
                return decodeURI(arr[2]);
            else {
                let strCookie = document.cookie;
                let arrCookie = strCookie.split("; ");
                for (var i = 0; i < arrCookie.length; i++) {
                    arr = arrCookie[i].split("=");
                    if (arr[0] == key) return arr[1];
                }
                return "";
            }

        }
        else {
            alert("浏览器不支持本地存储！");
        }
    }
    //删除本地数据
    DelLocalData(key) {
        if (window.localStorage) { localStorage.removeItem(key); }
        else if (window.sessionStorage) { sessionStorage.removeItem(key); }
        else if (window.navigator.cookieEnabled) {
            let exp = new Date();
            exp.setTime(exp.getTime() - 1);
            let cval = this.GetLocalData(key);
            if (cval != null)
                document.cookie = key + "=" + cval + ";expires=" + exp.toDateString() + ";path=/";
        }
        else {
            alert("浏览器不支持本地存储！");
        }
    }

    /**
     * 验证字段是否为空
     * @param obj 
     */
    IsNullOrEmpty(obj: any) {
        let f = false;
        try {
            if (typeof (obj) == "object") {
                if (obj.constructor != Array) {
                    throw new Error("对象不能判断");
                }
            }
            if (obj == undefined) {
                f = true;
            } else if (obj == null) {
                f = true;
            } else if (obj.length == 0) {
                f = true;
            } else if (obj == "") {
                f = true;
            }
            else if (obj == "null") {
                f = true;
            }


        }
        catch (ex) {
            console.log(ex);
            f = true;
        }


        return f;

    }
    /**
     * 弹出框
     * @param msg 
     */
    Alert(msg: string) {
        window.alert(msg);
    }
    /**
     * 确认框
     * @param msg 消息
     * @param Okfny  确认事件
     * @param canclefny  取消事件
     */
    Confirm(msg: string, Okfny: any = null, canclefny: any = null) {
        let r = window.confirm(msg);
        if (r) {
            if (Okfny != null) {
                Okfny();
            }

        } else {
            if (canclefny != null) {
                canclefny();
            }

        }

    }
    /**
     * 清除信息
     */
    ClearInfo() {
        this.DelLocalData("UID");
        this.DelLocalData("UserName");
        this.DelLocalData("UserToken");
        this.DelLocalData("UType");
        this.DelLocalData("UType");
        this.DelLocalData("UAudit");
        // if (window.localStorage) {  localStorage.clear(); }  
    }
    /**
     * 获取当前时间
     */
    GetNowFormatDate() {
        let date = new Date();
        let seperator1 = "/";
        let seperator2 = ":";
        let month = date.getMonth() + 1;
        let smonth = month + "";
        let strDate = date.getDate();
        let sstrDate = strDate + "";
        if (month >= 1 && month <= 9) {
            smonth = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            sstrDate = "0" + strDate;
        }
        var currentdate = date.getFullYear() + seperator1 + smonth + seperator1 + sstrDate
            + " " + date.getHours() + seperator2 + date.getMinutes()
            + seperator2 + date.getSeconds();
        return currentdate;

    }

    /**
     * 获取当前日期
     */
    GetNowFormatDate1() {
        let date = new Date();
        let seperator1 = "/";
        let month = date.getMonth() + 1;
        let smonth = month + "";
        let strDate = date.getDate();
        let sstrDate = strDate + "";
        if (month >= 1 && month <= 9) {
            smonth = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            sstrDate = "0" + strDate;
        }
        var currentdate = date.getFullYear() + seperator1 + smonth + seperator1 + sstrDate;
        return currentdate;

    }



    /**
     * 转换json时间
     * @param jsonDate 
     */
    jsonDateFormat(jsonDate) {//json日期格式转换为正常格式
        try {
            let date = new Date(parseInt(jsonDate.replace("/Date(", "").replace(")/", ""), 10));
            let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
            let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let seconds = date.getSeconds();
            let milliseconds = date.getMilliseconds();
            return date.getFullYear() + "/" + month + "/" + day + " " + hours + ":" + minutes + ":" + seconds + "." + milliseconds;
        } catch (ex) {
            return "";
        }
    }

    /**
     * 转换json日期
     * @param jsonDate 
     */
    jsonDateFormat1(jsonDate) {//json日期格式转换为正常格式
        try {
            let date = new Date(parseInt(jsonDate.replace("/Date(", "").replace(")/", ""), 10));
            let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
            let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            return date.getFullYear() + "/" + month + "/" + day;
        } catch (ex) {
            return "";
        }
    }
    /**
     * 格式化时间
     * @param fmt 
     * @param time 
     */
    dateFtt(fmt, time) { //author: meizz   

        time = this.jsonDateFormat(time);
        let arr = time.split(/[- : \/]/);
        let date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
        let o = {
            "M+": date.getMonth() + 1,                 //月份   
            "d+": date.getDate(),                    //日   
            "h+": date.getHours(),                   //小时   
            "m+": date.getMinutes(),                 //分   
            "s+": date.getSeconds(),                 //秒   
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度   
            "S": date.getMilliseconds()             //毫秒   
        };
        if (/(y+)/.test(fmt))
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(fmt))
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));

        return fmt;
    }
    /**
     * 判断是否为日期格式
     * @param time 
     */
    CheckDateTime(time) { //author: meizz   
        var reg = /^(\d+)-(\d{1,2})-(\d{1,2})$/;
        var reg1 = /^(\d+)\/(\d{1,2})\/(\d{1,2})$/;
        var r;
        var timeString = time.toString();
        if (timeString.indexOf('-') > -1) {
            r = time.match(reg);
        }
        else if (timeString.indexOf('/') > -1) {
            r = time.match(reg1);
        }
        if (r == null) return false;
        r[2] = r[2] - 1;
        var d = new Date(r[1], r[2], r[3]);
        if (d.getFullYear() != r[1]) return false;
        if (d.getMonth() != r[2]) return false;
        if (d.getDate() != r[3]) return false;
        return true;
    }
    /**
   * 判断是否为数字
   * @param time 
   */
    IsNotANumber(inputData) {
        var regPos = /^\d+$/; //非负浮点数
        if (regPos.test(inputData)) {
            return true;
        } else {
            return false;
        }

    }
    /**
     * 加密
     * @param data 
     */
    AESEncrypt(data: string) {
        if (!this.IsNullOrEmpty(data)) {
            let keyHex = CryptoJS.enc.Utf8.parse(this.Config.PwdKey);
            //模式为ECB padding为Pkcs7
            let encrypted = CryptoJS.DES.encrypt(data, keyHex, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            });
            //加密出来是一个16进制的字符串
            return encrypted.ciphertext.toString();
        } else {
            return "";

        }

    }
    /**
     * 解密
     * @param data 
     */
    AESDecrypt(data: string) {
        if (!this.IsNullOrEmpty(data)) {
            let keyHex = CryptoJS.enc.Utf8.parse(this.Config.PwdKey);
            let decrypted = CryptoJS.DES.decrypt({
                ciphertext: CryptoJS.enc.Hex.parse(data)
            }, keyHex, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            });
            //以utf-8的形式输出解密过后内容
            return decrypted.toString(CryptoJS.enc.Utf8);
        } else {
            return "";

        }
    }

    /**时间转换 毫秒转秒以 00:00显示*/
    MillisecondToTime(secs) {
        secs = secs / 1000;
        var hours = Math.floor(secs / 3600), minutes = Math.floor(secs % 3600 / 60), seconds = Math.ceil(secs % 3600 % 60);
        return (hours == 0 ? '' : hours > 0 && hours.toString().length < 2 ? '0' + hours + ':' : hours + ':') + (minutes.toString().length < 2 ? '0' + minutes : minutes) + ':' + (seconds.toString().length < 2 ? '0' + seconds : seconds);
    }

    /**时间转换 秒转秒以 00:00显示*/
    SecondsToTime(secs) {
        var hours = Math.floor(secs / 3600),
            minutes = Math.floor(secs % 3600 / 60),
            seconds = Math.ceil(secs % 3600 % 60);
        return (hours == 0 ? '00:' : hours > 0 && hours.toString().length < 2 ? '0' + hours + ':' : hours + ':') + (minutes.toString().length < 2 ? '0' + minutes : minutes) + ':' + (seconds.toString().length < 2 ? '0' + seconds : seconds);
    }

    /**
     * 获取随机数
     * @param nums 
     */
    GetRandomLettersAndNumbers(nums: number) {
        let result = "";
        let text2 = "1,2,3,4,5,6,7,8,9,0,a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z";
        let array = text2.split(',');
        for (let i = 0; i < array.length; i++) {
            let random = Math.ceil(Math.random() * 61);
            result += array[random];
        }
        return result;
    }
    /**
     * 日期格式化
     * @param fmt 
     * @param date 
     */
    dateFormat(fmt, date) {
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            };
        };
        return fmt;
    }
         /**
      * 获取系统版本
      */
     getOSType() {
        if (/(Android)/i.test(navigator.userAgent)) {
            return 0;
        } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
            return 1;
        } else {
            return 2;
        }
    }
    /**
     * 签名
     */
    APISign(callfn) {
        let date = new Date();
        let notice = this.GetRandomLettersAndNumbers(25);
        let create = this.dateFormat("YYYY-mm-ddTHH:MM:SSZ", date);
        let mypwd = Base64.stringify(sha256(notice + create + this.Config.AppSecret));
        let StringSign = "AppKey=\"" + this.Config.AppKey + "\",notice=\"" + notice + "\",create=\"" + create + "\",PasswordDigest=\"" + mypwd + "\"";
        callfn(StringSign);
        //return  StringSign;
    }
}