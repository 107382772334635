import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../server/UtilService';
import { APIService } from '../../server/APIService';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {
  pm: any;
  UserToken: any = "";
  OldUserToken:any="";
  /**类别集合 */
  FTypeList: any;
  //系统版本
  OSType:number;
  constructor(private utls: UtilService, private apiService: APIService) {
    /** 接收参数 */
    this.OSType=this.utls.getOSType();

    let userTokenIn = this.getQueryString("userToken");

    if(userTokenIn == undefined)
    {
      this.utls.SaveLocalData("UID", 0);
      this.utls.SaveLocalData("UserToken", "");
    }
    else if(userTokenIn =="")
    {
      this.utls.SaveLocalData("UID", 0);
      this.utls.SaveLocalData("UserToken", "");
    }
    else {
      //根据usertoken获取用户信息
      this.apiService.MUsers_GetUserDetailsForToken(userTokenIn,(dataUser)=>{
        console.log(dataUser);
        this.utls.SaveLocalData("UID", dataUser.UId);
        this.utls.SaveLocalData("UserToken", userTokenIn);
        this.UserToken = userTokenIn;
      });
    }

    

    // //测试使用
    // this.OldUserToken= "02567100162d4f8789d5f50e480d6941";
    // //根据usertoken获取用户信息
    // this.apiService.MUsers_GetUserDetailsForToken(this.OldUserToken,(dataUser)=>{
    //   console.log(dataUser);
    //   this.utls.SaveLocalData("UID", dataUser.UId);
    //   this.utls.SaveLocalData("UserToken", this.OldUserToken);
    //   this.UserToken = this.OldUserToken;
    // });


    //获取类目列表
    this.apiService.MTypes_GetPidList(0, (data) => {
      this.FTypeList = data;
    });
  }

  ///加载方法
  ngOnInit() {
    
  }

  /**
   * 大类点击事件
   * @param FTypeId 
   */
  clickTopItem(FTypeId) {
    this.utls.SetUrlParams("frequencylist1", { "fTypeId": FTypeId, "pageId": 1 });
  }

  getQueryString(name)
  {
    //console.log(name);
    //console.log(window.location);
    //解析url获取指定参数的值
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
      const search = window.location.hash.split('?')[1] || '';
      //const search = window.location.search.split('?')[1] || '';
      //console.log(window.location.hash.split('?')[1] || '');
      const r = search.match(reg) || [];
      return r[2];
  }
}
