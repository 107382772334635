import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { UtilService } from './UtilService';


@Injectable()
export class HttpService {

    constructor(public http: HttpClient, public pubUtil: UtilService) { }

    /**
     * 获取授权信息
     * @param SysType 0信息|1图书馆|2远程教学|3考试
     * @param callfn 返回执行
     */
    private PostAuth(SysType: number, callfn: any) {
        var result;
        // let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        // let url = this.pubUtil.Config.WebApiAuthUrl + "/api/OAuth/GetOpenOauth";
        // if (!this.pubUtil.IsNullOrEmpty(this.pubUtil.Config.AppKey)) {
        //     url += "?AppSecret=" + this.pubUtil.Config.AppSecret + "&AppKey=" + this.pubUtil.Config.AppKey + "&SysType=" + SysType;
        // }
        // this.http.post(url, null, new HttpHeaderResponse({ headers: headers })).toPromise()
        //     .then(data => this.handleSuccess(data, callfn))
        //     .catch(error => this.handleError(error));
        this.pubUtil.APISign(callfn);

        return result;
    }
    /**
     * 成功方法
     * @param result 结果
     * @param callfn 返回执行
     */
    private handleSuccess(result: any, callfn: any, errfn?: any) {
        if (result.code == "200") {
            callfn(result.obj);
        }
        else {
            if (errfn == null || errfn == undefined) {
                this.pubUtil.Alert(result.message);
                //this.pubUtil.Alert(result.message);
            } else {

                errfn(result);
            }
        }
    }

    /**
     * 成功方法
     * @param result 结果
     * @param callfn 返回执行
     */
    private handleSuccess1(result: any, callfn: any) {
        callfn(result);
    }
    /**
     * 错误方法
     * @param error 错误信息
     */
    private handleError(error: any) {
        this.pubUtil.Alert(error.message);
    }
    /**
     * post提交方法
     * @param SysType 0信息|1图书馆|2远程教学|3考试
     * @param url 路径
     * @param json Body内容
     * @param callfn 执行后
     */
    public Post(url: string, json: any, callfn: any, errfn?: any, SysType = 1) {
        this.PostAuth(SysType, sign => {
            this.PostAjax(url, json, callfn, sign, errfn, SysType);
        });
    }
    /**
     * Get方法
     * @param SysType 0信息|1图书馆|2远程教学|3考试
     * @param url 接口地址
     * @param callfn 执行成功后返回
     */
    public Get(url: string, callfn: any, errfn?: any, SysType = 1) {
        this.PostAuth(SysType, sign => {

            let UserToken = this.pubUtil.GetLocalData("UserToken");

            let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Sign': sign, 'UserToken': UserToken == null ? "" : UserToken });
            if (SysType == 0) //调用智慧校园接口
            {
                this.http.get(this.pubUtil.Config.WebApiAuthUrl + "/api/" + url, new HttpHeaderResponse({ headers: headers }))
                    .toPromise()
                    .then(res => this.handleSuccess(res, callfn, errfn))
                    .catch(error => this.handleError(error));
            }
            else if (SysType == 1)//调用图书馆接口
            {
                
                this.http.get(this.pubUtil.Config.WebApiUrl + url, new HttpHeaderResponse({ headers: headers }))
                    .toPromise()
                    .then(res => this.handleSuccess(res, callfn, errfn))
                    .catch(error => this.handleError(error));

            }
        });
    }
    /**
     * 模拟同步
     * @author czq  by  2018-07-07
     * @param url 接口地址
     * @param json Body地址
     * @param callfn 执行成功后返回
     * @param sign 签名
     */
    private PostAjax(url: string, json: any, callfn: any, sign: string, errfn?: any, SysType = 1) {
        let parms = JSON.stringify(json);
        let UserToken = this.pubUtil.GetLocalData("UserToken");

        if (SysType == 0) //调用智慧校园接口
        {
            let UserOldToken = this.pubUtil.GetLocalData("OldUserToken");
            let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Sign': sign, 'UserToken': UserOldToken == null ? "" : UserOldToken });
            this.http.post(this.pubUtil.Config.WebApiAuthUrl + "/api/" + url, parms, new HttpHeaderResponse({ headers: headers })).toPromise()
                .then(data => this.handleSuccess(data, callfn, errfn))
                .catch(error => { this.handleError(error) });
        } else if (SysType == 1)//调用图书馆接口
        {
            let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Sign': sign, 'UserToken': UserToken == null ? "" : UserToken });
            this.http.post(this.pubUtil.Config.WebApiUrl + url, parms, new HttpHeaderResponse({ headers: headers })).toPromise()
                .then(data => this.handleSuccess(data, callfn, errfn))
                .catch(error => { this.handleError(error) });
        }
    }
    /**
    * Get方法
    * @param url 接口地址
    * @param callfn 执行成功后返回
    */
    public ToolGet(url: string, callfn: any, errfn?: any, SysType = 1) {
        this.PostAuth(SysType, sign => {
            let UserToken = this.pubUtil.GetLocalData("UserToken");
            if (SysType == 0) //调用智慧校园接口
            {
                let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Sign': sign, 'UserToken': "" });
                this.http.get(this.pubUtil.Config.WebApiAuthUrl + "/api/" + url, new HttpHeaderResponse({ headers: headers }))
                    .toPromise()
                    .then(res => this.handleSuccess(res, callfn, errfn))
                    .catch(error => this.handleError(error));

            } else if (SysType == 1)//调用图书馆接口
            {
                let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Sign': sign, 'UserToken': UserToken == null ? "" : UserToken });
                this.http.get(this.pubUtil.Config.WebApiUrl + url, new HttpHeaderResponse({ headers: headers }))
                    .toPromise()
                    .then(res => this.handleSuccess(res, callfn, errfn))
                    .catch(error => this.handleError(error));

            }
        });
    }


    /**
     * 上传文件
     * @param url 上传文件接口 
     * @param objfile 文件
     * @param callfn 返回函数
     */
    public PostFile(url: string, objfile: any, callfn: any, errfn?: any, isdiyformdata: number = 0, diyfmd: any = null) {
        let fd = new FormData();
        if (isdiyformdata == 0) {
            let file = objfile[0];
            fd.append('upfile', file);
        } else {
            fd = diyfmd;
        }
        this.http.post(this.pubUtil.Config.WebApiUrl + url, fd, new HttpHeaderResponse()).toPromise().then(data => this.handleSuccess(data, callfn, errfn)).catch(error => (errfn == null || errfn == undefined) ? this.handleError(error) : errfn(error));
    }
    /**
     * 自定义URL获取Json数据
     * @param url 完整的数据URL
     * @param callfn 返回函数
     */
    public GetUrlJson(url: string, callfn: any) {
        let headers = new HttpHeaders(
            {
                'Content-Type': 'application/json'
            });
        this.http.get(url, new HttpHeaderResponse({ headers: headers }))
            .toPromise()
            .then(res => this.handleSuccess1(res, callfn))
            .catch(error => this.handleError(error));
    }
}
