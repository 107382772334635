import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../server/UtilService';
import { APIService } from '../../server/APIService';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-notelist',
  templateUrl: './notelist.component.html',
  styleUrls: ['./notelist.component.scss']
})

export class NoteListComponent implements OnInit {
  /**笔记集合 */
  NoteList: any = [];
  /**瀑布流 */
  subscribeScoll: any;
  /**瀑布流 */
  more_value = "加载更多";
  /**当前页码 */
  PageIndex: number = 1;
  /**总页码 */
  PageCount: number = 1;
  constructor(private utls: UtilService, private apiService: APIService) {
    //获取用户编号
    let UID = this.utls.GetLocalData("UID");
    if (UID <= 0) {
      this.utls.Alert("抱歉，我不清楚您是哪位小可爱，请先登录哦！");
      this.utls.GoBack();
    }
    else {
      //获取笔记列表
      this.GetDataList();
    }
  }

  ///加载方法
  ngOnInit() {
    // 监听滚动条滚动
    this.subscribeScoll = fromEvent(window, 'scroll').subscribe((event) => {
      this.onWindowScroll(); // 调用
    });
  }

  /**
  * 获取不同方向上滚动的距离
  */
  onWindowScroll() {
    var top = document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight;
    var content_height = window.innerHeight;
    if (height - top < content_height) {
      this.search_more();
    }
  }

  /**
   * 加载更多 
   */
  search_more() {
    if (this.PageIndex < this.PageCount) {
      this.PageIndex++;
      this.GetDataList();
    }
  }

  /**
 *  返回顶部 
 */
  top() {
    //$('html,body').animate({ scrollTop: 0 }, 300);
    window.scroll(0, 0);
  }

  /**
   * 获取音频集合
   */
  GetDataList() {
    //获取用户编号
    let UID = this.utls.GetLocalData("UID");
    //获取当前类型信息
    this.apiService.MNote_GetNoteInfoPaged(UID, 0, this.PageIndex, 6, (dataNote) => {
      if (this.PageIndex == 1) {
        this.NoteList = dataNote.Result;
      }
      else {
        for (var i = 0; i < dataNote.Result.length; i++) {
          this.NoteList.push(dataNote.Result[i]);
        }
      }
      this.PageCount = dataNote.PageCount;
      if (this.PageIndex >= this.PageCount) {
        this.more_value = "已全部加载";
      }
      else {
        this.more_value = "加载更多";
      }
    });
  }

  /**
     * 笔记点击事件
     * @param NId 
     */
  clickNoteItem(NId) {
    this.utls.SetUrlParams("noteitem", { "nid": NId });
  }

  /**
   * 返回上一页
   */
  clickBack() {
    this.utls.GoBack();
  }

  /**
   * 删除笔记
   */
  clickDelete(NId) {
    this.utls.Confirm("确定删除所选笔记吗？", () => {
      this.apiService.MNote_DeleteNoteByNId(NId, (data) => {
        if (data) {
          this.utls.Alert("删除成功!");
          this.PageIndex = 1;
          this.GetDataList();
        }
        else {
          this.utls.Alert("删除失败!");
        }
      });
    });
  }
}
