
import { Injectable } from '@angular/core';
import { HttpService } from './HttpService';
import { UtilService } from './UtilService';
@Injectable()
export class APIService {


    constructor(public httpService: HttpService, public pubutilservice: UtilService) {

    }

    //===============================MIS接口==============================================================================


     /**
    * 根据用户编号登录
    * @param bookID
    * @param RegistrationId
    * @param platFrom
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
   SUser_GetUserInfoByUserToken(UserToken,callfn:any,errfn?:any) {
    let url = "SUser/GetUserInfoByUserToken?UserToken="+UserToken;
    this.httpService.Post(url,"",callfn,errfn,0);
    }

     /**
    * 根据MISUserToken获取其他系统的UserToken
    * @param UserToken
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
   SUser_GetApiUserTokenByMISToken(UserToken,callfn:any,errfn?:any) {
    let url = "SUser/GetApiUserTokenByMISToken?UserToken="+UserToken+"&ApiType=1";
    this.httpService.Get(url,callfn,errfn,0);
    }

    //===============================MIS接口==============================================================================

    //===============================权限验证==============================================================================


    /**
     *验证用户UserToken超时
     */

    VerificationUserToken() {
        let UserTime = this.pubutilservice.GetLocalData("UserTime");
        let UserToken = this.pubutilservice.GetLocalData("UserToken");
        let UserTimeDate = new Date(UserTime);
        let UserTimeNewDate = UserTimeDate.setSeconds(UserTimeDate.getSeconds() + 7100);
        let endtime = new Date(this.pubutilservice.GetNowFormatDate());
        let endnewtime = endtime.setSeconds(endtime.getSeconds() + 0);
        if (UserTimeNewDate < endnewtime) {
            this.MUsers_GetUserDetailsForToken(UserToken, (data) => {
                if (data==null) {
                    this.pubutilservice.SetUrlParams("login");
                }
            }, (data) => {
                this.pubutilservice.SetUrlParams("login");
            })
        }

    }
    //=================================权限验证结束===================================================================================
    //==================特殊没有的===========================================
    /**
    * 清空用户的播放记录
    * @param UID
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
   MFrequency_DeleteHistoryByUID(UID:number,callfn:any,errfn?:any) {
    let url = "MFrequency/DeleteHistoryByUID?UID="+UID;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
* 分页获取资料、分类加在一起的列表（搜索）
* @param str
* @param page
* @param pagesize
* @param ordeByState
* @param callfn 正常代码200返回函数
* @param errfn 异常非200时返回函数 
*/ 
MFrequency_GetFrequenciesAndTypePaged(str:string,page:number,pagesize:number,ordeByState:number,callfn:any,errfn?:any) {
    let url = "MFrequency/GetFrequenciesAndTypePaged?str="+str+"&page="+page+"&pagesize="+pagesize+"&ordeByState="+ordeByState;
    this.httpService.Get(url, callfn,errfn);
    }



    //===================特殊的没有的====================================

    //====================================接口自动生成开始=============================================================================================
    /**
* 分页获取资料列表
* @param Tid
* @param IsHttpsPath
* @param page
* @param pagesize
* @param callfn 正常代码200返回函数
* @param errfn 异常非200时返回函数
*/ 
MFrequency_GetFrequenciesPaged(Tid:number,IsHttpsPath:any,page:number,pagesize:number,callfn:any,errfn?:any) {
    let url = "MFrequency/GetFrequenciesPaged?Tid="+Tid+"&IsHttpsPath="+IsHttpsPath+"&page="+page+"&pagesize="+pagesize;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 获取所有资料列表
    * @param Tid
    * @param IsHttpsPath
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MFrequency_GetFrequencies(Tid:number,IsHttpsPath:any,callfn:any,errfn?:any) {
    let url = "MFrequency/GetFrequencies?Tid="+Tid+"&IsHttpsPath="+IsHttpsPath;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 获取资料详细信息
    * @param Fid
    * @param IsHttpsPath
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MFrequency_GetFrequencyInfo(Fid:number,IsHttpsPath:any,callfn:any,errfn?:any) {
    let url = "MFrequency/GetFrequencyInfo?Fid="+Fid+"&IsHttpsPath="+IsHttpsPath;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 获取资料详细信息
    * @param FID
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MFrequency_GetFrequencyByFID(FID:any,callfn:any,errfn?:any) {
    let url = "MFrequency/GetFrequencyByFID?FID="+FID;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 获取下一个资料详细信息
    * @param Fid
    * @param IsHttpsPath
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MFrequency_GetNextFrequencyInfo(Fid:number,IsHttpsPath:any,callfn:any,errfn?:any) {
    let url = "MFrequency/GetNextFrequencyInfo?Fid="+Fid+"&IsHttpsPath="+IsHttpsPath;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 获取上一个资料详细信息
    * @param Fid
    * @param IsHttpsPath
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MFrequency_GetBackFrequencyInfo(Fid:number,IsHttpsPath:any,callfn:any,errfn?:any) {
    let url = "MFrequency/GetBackFrequencyInfo?Fid="+Fid+"&IsHttpsPath="+IsHttpsPath;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 资料增加点击数量
    * @param Fid
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MFrequency_UpdateTypesClick(Fid:number,callfn:any,errfn?:any) {
    let url = "MFrequency/UpdateTypesClick?Fid="+Fid;
    this.httpService.Post(url,null, callfn,errfn);
    }
    /**
    * 添加播放记录
    * @param addmodel
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MFrequency_AddFreHistory(addmodel,callfn:any,errfn?:any) {
    let url = "MFrequency/AddFreHistory";
    this.httpService.Post(url,addmodel, callfn,errfn);
    }
    /**
    * 获取用户的播放记录
    * @param UID
    * @param page
    * @param pagesize
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MFrequency_GetHistoryListPaged(UID:number,page:number,pagesize:number,callfn:any,errfn?:any) {
    let url = "MFrequency/GetHistoryListPaged?UID="+UID+"&page="+page+"&pagesize="+pagesize;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 获取用户某个分类下的播放历史记录
    * @param UID
    * @param Tid
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MFrequency_GetHistoryForTid(UID:number,Tid:number,callfn:any,errfn?:any) {
    let url = "MFrequency/GetHistoryForTid?UID="+UID+"&Tid="+Tid;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 批量删除图书
    * @param ItemsID
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MFrequency_DeleteBook(ItemsID:string,callfn:any,errfn?:any) {
    let url = "MFrequency/DeleteBook?ItemsID="+ItemsID;
    this.httpService.Post(url,null, callfn,errfn);
    }
    /**
    * 修改图书信息
    * @param model
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MFrequency_UpdateBook(model,callfn:any,errfn?:any) {
    let url = "MFrequency/UpdateBook";
    this.httpService.Post(url,model, callfn,errfn);
    }
    /**
    * 播放量统计
    * @param starttime
    * @param endtime
    * @param PlayFrom
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MFrequency_GetPlayrecord(starttime:string,endtime:string,PlayFrom:string,callfn:any,errfn?:any) {
    let url = "MFrequency/GetPlayrecord?starttime="+starttime+"&endtime="+endtime+"&PlayFrom="+PlayFrom;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 删除笔记
    * @param Nid
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MNote_DeleteNoteByNId(Nid:number,callfn:any,errfn?:any) {
    let url = "MNote/DeleteNoteByNId?Nid="+Nid;
    this.httpService.Post(url,null, callfn,errfn);
    }
    /**
    * 分页获取用户笔记
    * @param Uid
    * @param Fid
    * @param page
    * @param pagesize
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MNote_GetNoteInfoPaged(Uid:number,Fid:number,page:number,pagesize:number,callfn:any,errfn?:any) {
    let url = "MNote/GetNoteInfoPaged?Uid="+Uid+"&Fid="+Fid+"&page="+page+"&pagesize="+pagesize;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 获取用户笔记详情
    * @param Fid
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MNote_GetNoteInfoByFid(Fid:number,callfn:any,errfn?:any) {
    let url = "MNote/GetNoteInfoByFid?Fid="+Fid;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 获取用户笔记详情
    * @param Nid
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
   MNote_GetNoteInfo(Nid:number,callfn:any,errfn?:any) {
    let url = "MNote/GetNoteInfo?Nid="+Nid;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 写笔记
    * @param addNote
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MNote_AddNote(addNote,callfn:any,errfn?:any) {
    let url = "MNote/AddNote";
    this.httpService.Post(url,addNote, callfn,errfn);
    }
    /**
    * 修改笔记
    * @param upModel
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MNote_UpdateNote(upModel,callfn:any,errfn?:any) {
    let url = "MNote/UpdateNote";
    this.httpService.Post(url,upModel, callfn,errfn);
    }
    /**
    * 分页获取分类信息
    * @param Pid
    * @param page
    * @param pagesize
    * @param minnum
    * @param maxnum
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MTypes_GetTypes(Pid:number,page:number,pagesize:number,minnum:number,maxnum:number,callfn:any,errfn?:any) {
    let url = "MTypes/GetTypes?Pid="+Pid+"&page="+page+"&pagesize="+pagesize+"&minnum="+minnum+"&maxnum="+maxnum;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 分页获取全部多级分类信息
    * @param Pid
    * @param page
    * @param pagesize
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MTypes_GetTypesAll(Pid:number,page:number,pagesize:number,callfn:any,errfn?:any) {
    let url = "MTypes/GetTypesAll?Pid="+Pid+"&page="+page+"&pagesize="+pagesize;
    this.httpService.Get(url, callfn,errfn);
    }
     /**
    * 获取全部多级分类信息（同一级显示）
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MTypes_GetTypesAllOne(callfn:any,errfn?:any) {
    let url = "MTypes/GetTypesAllOne";
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 类目增加点击数量
    * @param Tid
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MTypes_UpdateTypesClick(Tid:number,callfn:any,errfn?:any) {
    let url = "MTypes/UpdateTypesClick?Tid="+Tid;
    this.httpService.Post(url,null, callfn,errfn);
    }
    /**
    * 根据父级编号获取类别
    * @param Pid
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MTypes_GetPidList(Pid:number,callfn:any,errfn?:any) {
    let url = "MTypes/GetPidList?Pid="+Pid;
    this.httpService.Post(url,null, callfn,errfn);
    }
    /**
    * 添加或修改类型
    * @param model
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MTypes_AddORUpdateTypes(model,callfn:any,errfn?:any) {
    let url = "MTypes/AddORUpdateTypes";
    this.httpService.Post(url,model, callfn,errfn);
    }
    /**
    * 批量删除类别
    * @param ItemsID
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MTypes_DeleteModel(ItemsID:string,callfn:any,errfn?:any) {
    let url = "MTypes/DeleteModel?ItemsID="+ItemsID;
    this.httpService.Post(url,null, callfn,errfn);
    }
    /**
    * 导入目录(只有mp3能获取时间等信息)
    * @param url
    * @param inSaveUp
    * @param tid
    * @param UId
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MTypes_ImportCategory(url1:string,inSaveUp:number,tid:number,UId:number,callfn:any,errfn?:any) {
    let url = "MTypes/ImportCategory?url="+url1+"&inSaveUp="+inSaveUp+"&tid="+tid+"&UId="+UId;
    this.httpService.Post(url,null, callfn,errfn);
    }
    /**
    * 获取一条类型信息
    * @param TID
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MTypes_GetTypesByID(TID:any,callfn:any,errfn?:any) {
    let url = "MTypes/GetTypesByID?TID="+TID;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 验证用户登录
    * @param userlogin
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MUsers_UserLogin(userlogin,callfn:any,errfn?:any) {
    let url = "MUsers/UserLogin";
    this.httpService.Post(url,userlogin, callfn,errfn);
    }
     /**
    * 根据用户编号登录
    * @param bookID
    * @param RegistrationId
    * @param platFrom
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
   MUsers_UserLoginByBookID(bookID,RegistrationId,platFrom,callfn:any,errfn?:any) {
    let url = "MUsers/UserLoginByBookID?bookID="+bookID+"&RegistrationId="+RegistrationId+"&platFrom="+platFrom;
    this.httpService.Get(url,callfn,errfn);
    }
    /**
    * 通过刷新Tooken登录
    * @param userRefreshLogin
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MUsers_RefreshUserLogin(userRefreshLogin,callfn:any,errfn?:any) {
    let url = "MUsers/RefreshUserLogin";
    this.httpService.Post(url,userRefreshLogin, callfn,errfn);
    }
    /**
    * 退出登录
    * @param UserToken
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MUsers_LoginOut(UserToken:string,callfn:any,errfn?:any) {
    let url = "MUsers/LoginOut?UserToken="+UserToken;
    this.httpService.Post(url,null, callfn,errfn);
    }
    /**
    * 获取一条用户信息
    * @param UID
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MUsers_GetUserByID(UID:any,callfn:any,errfn?:any) {
    let url = "MUsers/GetUserByID?UID="+UID;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 获取一条用户信息
    * @param UID
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MUsers_GetUserDetails(UID:any,callfn:any,errfn?:any) {
    let url = "MUsers/GetUserDetails?UID="+UID;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 通过Token获取用户信息，如果Token过期，则不返回信息
    * @param Token
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MUsers_GetUserDetailsForToken(Token:string,callfn:any,errfn?:any) {
    let url = "MUsers/GetUserDetailsForToken?Token="+Token;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 根据类型获取用户信息
    * @param UType
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MUsers_GetUseresList(UType:any,callfn:any,errfn?:any) {
    let url = "MUsers/GetUseresList?UType="+UType;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 根据类型获取用户信息（分页）
    * @param UType
    * @param reqStr
    * @param pageIndex
    * @param pageSize
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MUsers_GetUseresListByPage(UType:any,reqStr:string,pageIndex:number,pageSize:number,callfn:any,errfn?:any) {
    let url = "MUsers/GetUseresListByPage?UType="+UType+"&reqStr="+reqStr+"&pageIndex="+pageIndex+"&pageSize="+pageSize;
    this.httpService.Get(url, callfn,errfn);
    }
    /**
    * 新增用户
    * @param userRegModel
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MUsers_AddUser(userRegModel,callfn:any,errfn?:any) {
    let url = "MUsers/AddUser";
    this.httpService.Post(url,userRegModel, callfn,errfn);
    }
    /**
    * 修改用户密码
    * @param upmodel
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MUsers_ModUserPassWord(upmodel,callfn:any,errfn?:any) {
    let url = "MUsers/ModUserPassWord";
    this.httpService.Post(url,upmodel, callfn,errfn);
    }
    /**
    * 修改用户密码
    * @param upmodel
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MUsers_ModUserInfo(upmodel,callfn:any,errfn?:any) {
    let url = "MUsers/ModUserInfo";
    this.httpService.Post(url,upmodel, callfn,errfn);
    }
    /**
    * 批量删除用户
    * @param ItemsID
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MUsers_DeleteModel(ItemsID:string,callfn:any,errfn?:any) {
    let url = "MUsers/DeleteModel?ItemsID="+ItemsID;
    this.httpService.Post(url,null, callfn,errfn);
    }
    /**
    * 用户添加或修改
    * @param userRegModel
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MUsers_AddUpdateUser(userRegModel,callfn:any,errfn?:any) {
    let url = "MUsers/AddUpdateUser";
    this.httpService.Post(url,userRegModel, callfn,errfn);
    }
    /**
    * 用户添加或修改(不加密密码)
    * @param userRegModel
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MUsers_AddUpdateUserOther(userRegModel,callfn:any,errfn?:any) {
    let url = "MUsers/AddUpdateUserOther";
    this.httpService.Post(url,userRegModel, callfn,errfn);
    }
    /**
    * 批量添加用户
    * @param userRegModel
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    MUsers_AddUserList(userRegModel,callfn:any,errfn?:any) {
    let url = "MUsers/AddUserList";
    this.httpService.Post(url,userRegModel, callfn,errfn);
    }
    /**
    * JS开放授权
    * @param SchoolNum
    * @param callfn 正常代码200返回函数
    * @param errfn 异常非200时返回函数
    */ 
    OAuth_GetOpenOauth(SchoolNum:string,callfn:any,errfn?:any) {
    let url = "OAuth/GetOpenOauth?SchoolNum="+SchoolNum;
    this.httpService.Post(url,null, callfn,errfn);
    }
    
    
    
    
    




    //====================================接口自动生成结束=============================================================================================


}
