import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../server/UtilService';
import { APIService } from '../../server/APIService';

@Component({
  selector: 'app-noteitem',
  templateUrl: './noteitem.component.html',
  styleUrls: ['./noteitem.component.scss']
})

export class NoteItemComponent implements OnInit {
  pm: any;
  /**当前图书信息 */
  FrequencyModel = {
    "FId": 0,
    "FName": "",
    "FPath": "",
    "TId": 0,
    "FTime": "",
    "FClick": 0,
    "UId": 0,
    "CardId": 0,
    "FContent": "",
    "VideoDescription": "",
    "TimeString": "",
    "TimeLong": 0,
    "FileSize": 0
  }
  /**当前笔记信息 */
  NoteModel = {
    "Nid": 0,
    "Uid": 0,
    "Fid": 0,
    "NContent": "string",
    "NTitle": "string",
    "NEndTime": "2019-12-31T02:18:26.116Z",
    "PegTime": "string",
    "TypeName": "string",
    "TypeImage": "string",
    "TID": 0
  }
  constructor(private utls: UtilService, private apiService: APIService) {
    //获取用户编号
    let UID = this.utls.GetLocalData("UID");
    if (UID <= 0) {
      this.utls.Alert("抱歉，我不清楚您是哪位小可爱，请先登录哦！");
      this.utls.GoBack();
    }
    else {
      /** 接收参数 */
      this.pm = this.utls.GetUrlParams();

      if (this.pm == null) {
        this.utls.Alert("获取信息失败！");
        this.utls.GoBack();
      }
      else {
        if (this.pm.nid == null) {
          this.utls.Alert("获取信息失败！");
          this.utls.GoBack();
        }
        else {
          //获取当前笔记信息
          this.apiService.MNote_GetNoteInfo(this.pm.nid, (dataNote) => {
            if (dataNote == null) {
              this.utls.Alert("获取信息失败！");
              this.utls.GoBack();
            }
            this.NoteModel = dataNote;
            //获取图书信息
            this.apiService.MFrequency_GetFrequencyByFID(this.NoteModel.Fid, (dataFrequency) => {
              if (dataFrequency == null) {
                this.utls.Alert("获取信息失败！");
                this.utls.GoBack();
              }
              this.FrequencyModel = dataFrequency;
            });
          });
        }
      }
    }
  }

  ///加载方法
  ngOnInit() {
  }

  /**
   * 返回上一页
   */
  clickBack() {
    this.utls.GoBack();
  }

  /**
   * 保存笔记
   */
  clickSubmit() {
    //根据UserToken获取用户信息 
    let UID = this.utls.GetLocalData("UID");
    //修改
    let NoteUpdateModel = {
      "UID": UID,
      "NID": this.NoteModel.Nid,
      "NContent": this.NoteModel.NContent
    }
    this.apiService.MNote_UpdateNote(NoteUpdateModel, (dataBool) => {
      if (dataBool) {
        this.utls.Alert("笔记修改成功！");
        this.utls.GoBack();
      }
      else {
        this.utls.Alert("笔记修改失败！");
      }
    });
  }
}
