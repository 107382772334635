import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {PipesModule} from'../pipes/pipes.module';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
//主页
import { IndexComponent } from '../pages/home/index.component';
//图书列表页面
import { FrequencyListComponent } from '../pages/frequency/frequencylist.component';
//图书详情页面
import { FrequencyItemComponent } from '../pages/frequency/frequencyitem.component';
//图书详情pdf显示页面
import { FrequencyItemPdfComponent } from '../pages/frequency/frequencyitempdf.component';
//笔记列表页面
import { NoteListComponent } from '../pages/note/notelist.component';
//笔记详情页面
import { NoteItemComponent } from '../pages/note/noteitem.component';
//播放记录页面
import { FrequencyRecordComponent } from '../pages/frequency/frequencyrecord.component';
//搜索页面
import { SearchComponent } from '../pages/search/search.component';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    FrequencyListComponent,
    FrequencyItemComponent,
    FrequencyItemPdfComponent,
    NoteListComponent,
    NoteItemComponent,
    FrequencyRecordComponent,
    SearchComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    PipesModule,
    PdfJsViewerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
