import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../server/UtilService';
import { APIService } from '../../server/APIService';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-frequencylist',
  templateUrl: './frequencylist.component.html',
  styleUrls: ['./frequencylist.component.scss']
})

export class FrequencyListComponent implements OnInit {
  pm: any;
  /**当前类别实体 */
  FTypeModel = {
    "TId": 0,
    "TName": "",
    "FId": 0,
    "TTime": "",
    "TClick": 0,
    "TTop": 0,
    "CId": 0,
    "TypeImage": ""
  }
  /**类别集合 */
  FTypeList: any = [];
  /**图书集合 */
  FrequencyList: any = [];
  /**瀑布流 */
  subscribeScoll: any;
  /**瀑布流 */
  more_value = "加载更多";
  /**当前页码 */
  PageIndex: number = 1;
  /**总页码 */
  PageCount: number = 1;
  tabIndexInt = 1;

  constructor(private utls: UtilService, private apiService: APIService) {
    /** 接收参数 */
    this.pm = this.utls.GetUrlParams();

    if (this.pm == null) {
      this.utls.Alert("获取信息失败！");
      this.utls.GoBack();
    }
    else {
      if (this.pm.fTypeId == null) {
        this.utls.Alert("获取信息失败！");
        this.utls.GoBack();
      }
      else {
        //获取当前类型信息
        this.apiService.MTypes_GetTypesByID(this.pm.fTypeId, (dataFT) => {
          if (dataFT == null) {
            this.utls.Alert("获取信息失败！");
            this.utls.GoBack();
          }
          this.FTypeModel = dataFT;
          //增加点击率
          this.apiService.MTypes_UpdateTypesClick(this.pm.fTypeId, (dataClick) => {
          });
        });
        //获取类型列表
        this.apiService.MTypes_GetPidList(this.pm.fTypeId, (data) => {
          this.FTypeList = data;
        });
        //获取音频列表
        this.GetDataList();
      }
    }
  }

  ///加载方法
  ngOnInit() {
    // 监听滚动条滚动
    this.subscribeScoll = fromEvent(window, 'scroll').subscribe((event) => {
      this.onWindowScroll(); // 调用
    });
  }

  /**
  * 获取不同方向上滚动的距离
  */
  onWindowScroll() {
    var top = document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight;
    var content_height = window.innerHeight;
    if (height - top < content_height) {
      this.search_more();
    }
  }

  /**
   * 加载更多 
   */
  search_more() {
    if (this.PageIndex < this.PageCount) {
      this.PageIndex++;
      this.GetDataList();
    }
  }

  /**
 *  返回顶部 
 */
  top() {
    //$('html,body').animate({ scrollTop: 0 }, 300);
    window.scroll(0, 0);
  }

  /**
   * 获取音频集合
   */
  GetDataList() {
    this.apiService.MFrequency_GetFrequenciesPaged(this.pm.fTypeId, false, this.PageIndex, 10, (data) => {
      if (this.PageIndex == 1) {
        this.FrequencyList = data.Result;
      }
      else {
        for (var i = 0; i < data.Result.length; i++) {
          this.FrequencyList.push(data.Result[i]);
        }
      }
      this.PageCount = data.PageCount;
      if (this.PageIndex >= this.PageCount) {
        this.more_value = "已全部加载";
      }
      else {
        this.more_value = "加载更多";
      }
    });
  }

  /**
     * 类别点击事件
     * @param FTypeId 
     */
  clickTopItem(FTypeId) {
    if (this.pm.pageId == null) {
      this.utls.SetUrlParams("frequencylist2", { "fTypeId": FTypeId, "pageId": 2 });
    }
    else {
      if (this.pm.pageId == 1) {
        this.utls.SetUrlParams("frequencylist2", { "fTypeId": FTypeId, "pageId": 2 });
      }
      else {
        this.utls.SetUrlParams("frequencylist1", { "fTypeId": FTypeId, "pageId": 1 });
      }
    }
  }

  /**
     * 图书点击事件
     * @param FId 
     */
  clickFrequencyItem(FId, itemIndex) {
    if (itemIndex <= 2) {
      this.utls.SetUrlParams("frequencyitem", { "fid": FId });
    }
    else {
      //获取用户编号
      let UID = this.utls.GetLocalData("UID");
      if (UID <= 0) {
        this.utls.Alert("您只能阅读前三篇，如需继续阅读请登录！");
      }
      else {
        this.utls.SetUrlParams("frequencyitem", { "fid": FId });
      }
    }
  }

  /**
   * 返回上一页
   */
  clickBack() {
    this.utls.GoBack();
  }

  freTopTab(tabIndex) {
    this.tabIndexInt = tabIndex;
  }
}
