import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule,HttpClientJsonpModule } from '@angular/common/http';
import { UtilService } from '../server/UtilService';
import { HttpService } from '../server/HttpService';
import { APIService } from '../server/APIService';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
//主页
import { IndexComponent } from '../pages/home/index.component';
//图书列表页面
import { FrequencyListComponent } from '../pages/frequency/frequencylist.component';
//图书详情页面
import { FrequencyItemComponent } from '../pages/frequency/frequencyitem.component';
//图书详情pdf显示页面
import { FrequencyItemPdfComponent } from '../pages/frequency/frequencyitempdf.component';
//笔记列表页面
import { NoteListComponent } from '../pages/note/notelist.component';
//笔记详情页面
import { NoteItemComponent } from '../pages/note/noteitem.component';
//播放记录页面
import { FrequencyRecordComponent } from '../pages/frequency/frequencyrecord.component';
//搜索页面
import { SearchComponent } from '../pages/search/search.component';

const routes: Routes = [
  { path: '', redirectTo: '/index', pathMatch: 'full' },//默认首页
  //主页
  { path: 'index', component: IndexComponent },
  { path: 'index/:Param', component: IndexComponent },
  //图书列表页面
  { path: 'frequencylist1', component: FrequencyListComponent },
  { path: 'frequencylist1/:Param', component: FrequencyListComponent },
  { path: 'frequencylist2', component: FrequencyListComponent },
  { path: 'frequencylist2/:Param', component: FrequencyListComponent },
  //图书详情页面
  { path: 'frequencyitem', component: FrequencyItemComponent },
  { path: 'frequencyitem/:Param', component: FrequencyItemComponent },
  //图书详情pdf显示页面
  { path: 'frequencyitempdf', component: FrequencyItemPdfComponent },
  { path: 'frequencyitempdf/:Param', component: FrequencyItemPdfComponent },
  //笔记列表页面
  { path: 'notelist', component: NoteListComponent },
  //笔记详情页面
  { path: 'noteitem', component: NoteItemComponent },
  { path: 'noteitem/:Param', component: NoteItemComponent },
  //播放记录
  { path: 'frequencyrecord', component: FrequencyRecordComponent },
  //搜索记录
  { path: 'search', component: SearchComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
  ],
  providers: [
    UtilService,
    HttpService,
    APIService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
